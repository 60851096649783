import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './PostRelatedCategories.scss'

export const PostRelatedCategories = (props) => {
    const { categories, filterCategories } = props
    const filter = filterCategories && categories && categories.edges ? categories.edges.filter(a => filterCategories.includes(a.node.wordpress_id)) : categories && categories.edges ? categories.edges : categories
    return (

      filter ? (
        <section className="realted-categories">
        <div className="taxonomy">
          <h4>Categories</h4>
          <ul className="taglist">
            {filter[0].node ?
              filter.map(({node: category}, index) => (
                <li key={`${index}cat`}>
                  {category.path &&
                    <GatsbyLink to={category.path}>
                      {category.name}
                    </GatsbyLink>
                  }
                </li>
              ))
              :
              filter.map((category, index) => (
                <li key={`${index}cat`}>
                  {category.path &&
                    <GatsbyLink to={category.path}>
                      {category.name}
                    </GatsbyLink>
                  }
                </li>
              ))
            }
          </ul>
        </div>
        </section>
      ) : null

    )
}
