import React from 'react';
import './PageHeader.scss';
import { ImagePass } from '../Image/ImagePass';
import { Breadcrumbs } from '../Breadcrumbs';
import { decodeEntities } from '../../utils/helpers';

export const PageHeader = (props) => {
  const { headerTitle, headerSubTitle, headerBackgroundImage, location, compact = true } = props;
  return (
    <section className={`header-page${compact ? ' compact' : ''}${!headerBackgroundImage ? ' invert': ''}`}>
        {!headerBackgroundImage && <Breadcrumbs location={location}/>}
        <div className="title-block">
          <div className="wrap">
            <div className="inner">
              {headerTitle && <h1 className="title" dangerouslySetInnerHTML={{__html: headerTitle}} />}
              {headerSubTitle && <div className="sub-title" dangerouslySetInnerHTML={{ __html: decodeEntities(headerSubTitle) }} />}
            </div>
          </div>
        </div>

        {headerBackgroundImage && <div className="hero"><ImagePass src={headerBackgroundImage} className="background" /></div>}

    </section>
  )
}
