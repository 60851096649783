import React from 'react'
import { graphql } from 'gatsby'
import GatsbyLink from '../components/GatsbyLink'
import Previewable from '../components/Previewable'
import SEO from '../components/SEO'
import { PageHeader } from '../components/PageHeader'
import RelatedProjects from '../components/Posts/Related/RelatedProjects'
import RelatedProducts from '../components/Posts/Related/RelatedProducts'
import { RenderShortcode } from '../components/RenderShortcode'
import { Image } from '../components/Image'
import './post.scss'
import './project.scss'
import { decodeEntities } from '../utils/helpers'
import { GlobalTrending } from '../components/Acf/GlobalTrending'
import { PrevNextNavigation } from '../components/Posts/PrevNextNavigation'
import { PostRelatedCategories } from '../components/Posts/PostRelatedCategories'
import Carousel from '../components/Carousel'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

export const ProjectPostTemplate = ({ content, title, date, pageContext }) => {
  return (
    <section className="single-post project">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const ProjectPost = props => {
  const { data, location, pageContext } = props
  const {
    wordpressPost: post,
    wordpressWpSettings,
    categories,
    siteSettings,
  } = data
  if (!post) return null
  const { title, content, yoast, date, acf } = post

  const { newsletterText } = siteSettings.options
  const relatedProjectCheck = acf && acf.relatedProjects && acf.relatedProjects
  const relatedProductCheck = acf && acf.relatedProducts && acf.relatedProducts
  const settings = {
    container: `product-gallery-carousel`,
    nav: true,
    mouseDrag: true,
    controls: true,
    loop: true,
    gutter: 20,
    slideBy: 1,
    speed: 600,
    center: true,
    //controlsContainer: '.related-nav',
    responsive: {
      1600: {
        items: 1,
      },
      1024: {
        items: 1,
      },
      640: {
        items: 1,
      },
      420: {
        items: 1,
      },
    },
  }
  return (
    <>
      <div>
        <SEO
          title={`${yoast.metaTitle ||
            decodeEntities(title)} | ${decodeEntities(
            wordpressWpSettings.title
          )}`}
          desc={yoast.metaDescription}
          yoast={yoast}
        />

        <PageHeader
          headerTitle={title}
          headerSubTitle={date}
          headerBackgroundImage=""
          location={location}
        />

        <section className="project-gallery">
          <div className="wrap">
            <Carousel settings={settings}>
              {acf &&
                acf.projectGallery &&
                acf.projectGallery.map((image, index) => (
                  <div className="slide" key={index}>
                    <div className="image">
                      <img src={image.source_url} loading="lazy" />
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </section>

        <ProjectPostTemplate
          content={content}
          categories={categories}
          title={title}
          date={date}
          blogSlug="projects"
          pageContext={pageContext}
        />

        <PostRelatedCategories
          categories={categories}
          filterCategories={post.project_category}
        />

        <PrevNextNavigation pageContext={pageContext} archive="/projects/" />

        {relatedProductCheck && (
          <RelatedProducts
            title="Products Used"
            contentType="products"
            postFilter={relatedProductCheck}
            action={{ url: `/products/`, title: 'View all' }}
          />
        )}

        <RelatedProjects
          title="Related Projects"
          contentType="projects"
          postFilter={relatedProjectCheck}
          action={{ url: `/projects/`, title: 'View all' }}
        />

        <GlobalTrending />
      </div>
      <NewsletterSignUp content={newsletterText} />
    </>
  )
}

export default Previewable(ProjectPost, 'projects')

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      acf {
        projectGallery {
          source_url
          title
        }
        relatedProducts
        relatedProjects
      }
      project_category
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
