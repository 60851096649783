import React from 'react'
import GatsbyLink from '../../GatsbyLink'
import './PrevNextNavigation.scss'
import { decodeEntities } from '../../../utils/helpers'

export const PrevNextNavigation = (props) => {
    const { pageContext, archive } = props
    const { next, prev } = pageContext;
    return (
      <section className="prev-next">
        <div className="wrap">
          <div className="inner">
            {prev ? <GatsbyLink className="prev" title={decodeEntities(prev.name)} to={prev.path}>Prev<span>{decodeEntities(prev.name)}</span></GatsbyLink> : <span className="prev"/>}
            {archive ? <GatsbyLink className="archive" to="/projects/">Back to Archive</GatsbyLink> : <span className="archive"/>}
            {next ? <GatsbyLink className="next" title={decodeEntities(next.name)} to={next.path}>Next<span>{decodeEntities(next.name)}</span></GatsbyLink> : <span className="next"/>}
          </div>
        </div>
      </section>
    )
}
